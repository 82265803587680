<template>
  <v-card>
    <v-card-title primary-title>
      Volume Incentive Rebate (VIR) Statement
    </v-card-title>
    <template v-slot:toolbar>
      <div class="text-right">
        April 2020 Statement
        <a href="#">
          <i class="fa fa-file-pdf text-danger ml-3" v-b-tooltip.hover.bottom="'Download Statement'" />
        </a>
      </div>
    </template>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="4">
            <v-card height="100%">
              <v-card-title>
                Purchases
              </v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <th width="50%" class="text-right">
                          April 2020 Purchases
                        </th>
                        <td width="50%">$55,664</td>
                      </tr>
                      <tr>
                        <th width="50%" class="text-right">
                          YTD Purchases:
                        </th>
                        <td width="50%">$396,511.65</td>
                      </tr>
                      <tr>
                        <th width="50%" class="text-right">
                          Volume Rebate %
                        </th>
                        <td width="50%">5%</td>
                      </tr>
                      <tr>
                        <th width="50%" class="text-right">
                          Volume Rebate Amount
                        </th>
                        <td width="50%">$19,825</td>
                      </tr>
                      <tr>
                        <th width="50%" class="text-right">
                          Credit Memo #
                        </th>
                        <td width="50%">96855986</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-card height="100%">
              <v-card-title>
                Volume Targets
              </v-card-title>
              <v-card-text>
                <v-data-table :items="items" :headers="headers" hide-default-footer></v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      selectedCount: 0,
      isBusy: false,
      currentPage: 1,
      filters: {},
      items: [
        {
          target: "Volume Target",
          target_1: "$50,000",
          target_2: "$125,000",
          target_3: "$200,000",
          target_4: "$300,000"
        },
        {
          target: "Rebate",
          target_1: "2% Rebate",
          target_2: "3% Rebate",
          target_3: "4% Rebate",
          target_4: "5% Rebate"
        }
      ],
      headers: [
        {
          value: "target",
          text: ""
        },
        {
          value: "target_1",
          text: "Target 1",
          class: "text-center blue lighten-4"
        },
        {
          value: "target_2",
          text: "Target 2",
          class: "text-center blue lighten-3"
        },
        {
          value: "target_3",
          text: "Target 3",
          class: "text-center blue lighten-2"
        },
        {
          value: "target_4",
          text: "Target 4",
          class: "text-center blue lighten-1"
        }
      ],
      page: {
        size: 10,
        totalElements: 0,
        totalPages: 0,
        sort: null
      }
    };
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      { title: "Reports" },
      { title: "Volume Incentive Rebate (VIR) Statement", route: "vir-report" }
    ]);
  }
};
</script>
